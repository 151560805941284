import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import './index.css';
import { App, serviceWorker } from './app';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

var firebaseConfig = {
  apiKey: "AIzaSyAvkEnlvDDpM__LVCOSv-UukY-7Eaf12Mo",
  authDomain: "leroy-7649b.firebaseapp.com",
  projectId: "leroy-7649b",
  storageBucket: "leroy-7649b.appspot.com",
  messagingSenderId: "613996570537",
  appId: "1:613996570537:web:e4a5f0aa5848fd9186c6b8",
  measurementId: "G-ZF8HVKH0PZ"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

// firebase.functions().useEmulator("localhost", 5001);

// This is where the magic happens. React renders our App component
// inside the div with the id "root"
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
