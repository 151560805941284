import firebase from 'firebase/app';
import { inspect } from 'util';

export const subscribeToAvailableTimes = (restaurantId, date, cb) => {
    console.log("avail date = " + date);
    console.log("rest id = " + restaurantId);

    const callback = results => {

        const dateAvailabilityDoc = results.docs[0];
        console.log("avail " + inspect(results.docs));
        if (dateAvailabilityDoc) {
            cb({
                id: dateAvailabilityDoc.id,
                availableTimes: dateAvailabilityDoc.data().availableTimes,
            });
        } else {
            cb({ id: '', availableTimes: [] });
        }
    }

    return firebase.firestore()
        .collection('dateAvailabilities')
        .where('restaurantId', '==', restaurantId)
        .where('date', '==', date)
        .onSnapshot(callback);
}